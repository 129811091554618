export var POST_METHOD = {

	UPLOAD_DOCUMENT: '/api/admin/upload_document',
	GET_COMPANY_LIST: '/api/company/get_company_list',
	GET_COUNTRY_CITY_LIST: '/api/get_country_city_list',
	GET_ALL_COUNTRY_LIST: '/api/get_all_country_list',
	GET_COUNTRY_DATA: '/api/get_country_data',
	UPDATE_CITY_DETAIL: '/api/update_city_detail',
	ADD_CITY_DETAIL: '/api/add_city_detail',
	UPDATE_COUNTRY_DETAIL: '/api/update_country_detail',
	ADD_COUNTRY_DETAIL: '/api/add_country_detail',
	GET_SERVICE_LIST: '/api/admin/get_service_list',
	COMPANY_SETTLEMENT: '/api/company_settlement',
	UPDATE_SERVICE_DETAIL: '/api/update_service_detail',
	ADD_SERVICE_DETAIL: '/api/add_service_detail',
	UPDATE_SUB_SERVICE_DETAIL: '/api/update_sub_service_detail',
	ADD_SUB_SERVICE_DETAIL: '/api/add_sub_service_detail',
	UPDATE_SERVICE_PRICE_DETAIL: '/api/update_service_price_detail',
	ADD_SERVICE_PRICE_DETAIL: '/api/add_service_price_detail',
	GET_SERVICE_PRICE_LIST: '/api/get_service_price_list',
	UPDATE_SUB_SERVICE_SEQUENCE_NUMBER: '/api/update_sub_service_sequence_number',
	GET_USER_LIST: '/api/get_user_list',
	GET_PROVIDER_LIST: '/api/get_provider_list',
	USER_APPROVE_DECLINE: '/api/user_approve_decline',
	PROVIDER_APPROVE_DECLINE: '/api/provider_approve_decline',
	ADD_NEW_LANGUAGE : '/api/admin/add_new_language',
	COMPANY_APPROVE_DECLINE: '/api/company/approve_decline',
	UPDATE_USER_DETAIL: '/api/update_user_detail',
	UPDATE_PROVIDER_DETAIL: '/api/update_provider_detail',
	GET_CITY_LIST: '/api/get_city_list',
	GET_SETTING_DETAIL: '/api/admin/get_setting',
	UPDATE_SETTING_DETAIL: '/api/admin/update_setting',
	CHECK_CITY_EXIST: '/api/check_city_exist',
	GET_USER_DOCUMENT_LIST: '/api/admin/get_user_document_list',
	GET_STRING_LIST:"/api/admin/get_string_list",
	COUNTRY_DETAIL:'/api/country_detail',

	GET_REQUESTS_LIST: '/api/get_request_list',
	GET_HISTORY: '/api/get_history',
	GET_EARNING: '/api/get_earning',
	GET_NOTIFICATIONS: '/api/get_notifications',
	PROVIDER_SETTLEMENT: '/api/provider_settlement',
	GET_DASHBOARD_TOTAL: '/api/get_dashboard_total',
	GET_REQUEST_CHART_DATA: '/api/get_request_chart_data',
	GET_USER_PROVIDER_CHART_DATA: '/api/get_user_provider_chart_data',
	GET_USER_REQUEST_ANALYTICS_DATA: '/api/get_user_request_analytics_data',
	GET_TOP_PROVIDER_EARNING_DATA: '/api/get_top_provider_earning_data',

	GET_ADMIN_LIST: '/api/admin/list',
	ADD_ADMIN_DATA: '/api/admin/add',
	UPDATE_ADMIN_DATA: '/api/admin/update',
	DELETE_ADMIN_DATA: '/api/admin/delete',
	ADMIN_LOGIN: '/api/admin/login',

	GET_WALLET_HISTORY_LIST: '/api/admin/get_wallet_history_list',
	GET_DOCUMENT_LIST: '/api/admin/document_list',
	GET_COMPANY_DOCUMENT_LIST: '/api/admin/get_document_list',
	UPDATE_DOCUMENT_DATA: '/api/admin/update_document',
	ADD_DOCUMENT_DATA: '/api/admin/add_document',

	DELETE_USER: '/api/user/delete_account',
	DELETE_PROVIDER: '/api/provider/delete_account',
	DELETE_COMPANY: '/api/company/delete_account',

}