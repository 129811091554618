import { Component, NgZone, OnInit, ElementRef, ViewChild, Injectable, Inject, Renderer2 } from '@angular/core';
import { menu_title, title, status, validation_message, button, heading_title } from './string';
import { POST_METHOD } from './http_methods';
import { REQUEST_STATE, REQUEST_STATUS_ID, PAYMENT_TYPE, SETTLED_BY, CONSTANT, USER_TYPE } from './constant';
import { map } from "rxjs/operators";
import { TempStorage } from './temp_storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import {TranslateService} from '@ngx-translate/core';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Injectable()
export class Helper {

    stop_loader: boolean = false
    loader: boolean = false;
    public title: any = title;
    public status: any = status;
    public menu_title: any = menu_title;
    public validation_message: any = validation_message;
    public button: any = button;
    public heading_title: any = heading_title;
    public POST_METHOD: any = POST_METHOD;
    public PAYMENT_TYPE: any = PAYMENT_TYPE;
    public REQUEST_STATE: any = REQUEST_STATE;
    public REQUEST_STATUS_ID: any = REQUEST_STATUS_ID;
    public CONSTANT: any = CONSTANT;
    public USER_TYPE: any = USER_TYPE;
    public SETTLED_BY: any = SETTLED_BY;
    public dialog_ref: any;
    public price_type_array: any[] = [
        { title: this.title.fix, value: 0 },
        { title: this.title.fix_hourly, value: 1 },
        // {title: 'Hourly Slot', value: 2},
    ]
    public PRICE_TYPE: any = {
        FIXED: 0,
        FIXED_HOURLY: 1,
        HOURLY_SLOT: 2
    }
    public per_page_record: any = [
        10,
        20,
        50,
        100,
    ]

    constructor(public http: Http, public trans:TranslateService, public temp_storage: TempStorage, public router: Router, public dialog: MatDialog, public toastr: ToastrManager) {

    }

    // http post method
    // loader - true if loading needed
    // stop_loader = true if loading stop
    // success_message - true if success message show
    // error_message - true if error message show
    http_post_method_requester(api_name, parameter, loader, stop_loader, success_message, error_message, response) {

        this.loader = loader;
        let header = new Headers();
        // for basic auth
        header.append("Authorization", "Basic " + btoa("test:test"));
        header.append("type", '1');

        let admin_detail: any = localStorage.getItem('admin_detail');
        // console.log(admin_detail)
        if (admin_detail) {
            admin_detail = JSON.parse(admin_detail);
            header.append("id", admin_detail._id.toString());
            header.append("server_token", admin_detail.server_token.toString());
        }
        const httpOptions = new RequestOptions({ headers: header });

        var base_url = CONSTANT.BASE_URL;
        api_name = base_url + api_name;

        // console.log("api_name: "+api_name)
        this.http.post(api_name, parameter, httpOptions).pipe(map((res) => res.json())).subscribe((res_data) => {
            console.log(res_data)
            

            if (res_data.success) {
		this.loader = false;
                if (stop_loader) {
                    this.loader = false;
                }
                if (success_message == true && res_data.code) {
                    if(res_data.message){
                        this.toastr.successToastr(res_data.message, 'Success!');
                    }
                }
                response(res_data);
            } else {
                if (error_message == true && res_data.code) {
                    this.toastr.errorToastr(res_data.message, 'Oops!');
                }
                if (res_data.code == 999 || res_data.code == 998) {
                    localStorage.removeItem('admin_detail');
                    this.router.navigate(['login'])
                } else {
                    response(res_data);
                }
            }
            if (stop_loader) {
                this.loader = false;
            }
        }, (error) => {
            response(error)
        });
    }

    phone_number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

    downloadFile(data: any) {
        var blob = new Blob([data], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', url + '.csv');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    downloadStringFile(data:any){
        var name;
        if(data.type == 'admin_string'){
            name = 'admin_string'
        }else{
            name = 'user_string'
        }
       
        var blob = new Blob([data.string], { type: 'json' });
        var url = window.URL.createObjectURL(blob);
        var element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', name + '.json');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    space_validation(evt){
        if(evt.code == "Space" && evt.target.value.length < 1){
          return false;
        }
        return true
    }


    check_version(num){
        var version_validation = /^[0-9]((\.)[0-9]){0,2}$/;
        console.log(!version_validation.test(num))
        if(!version_validation.test(num)){
            return false;
        }
        return true;
    }

}
