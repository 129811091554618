import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Helper } from '../../../app/helper';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor(public helper: Helper,) {}
  iconMenu: IMenuItem[] = [
    {
      name: "HOME",
      type: "icon",
      tooltip: "Home",
      icon: "home",
      state: "admin/dashboard"
    },
    {
      name: "PROFILE",
      type: "icon",
      tooltip: "Users",
      icon: "person",
      state: "admin/users"
    },
    {
      name: "Payment",
      type: "icon",
      tooltip: "Payment",
      icon: "payment",
      state: "admin/earning"
    },
    
    {
      name: this.helper.trans.instant("dashboard"),
      type: "link",
      tooltip: "Dashboard",
      icon: "admin_icons/dashboard.png",
      state: "admin/dashboard"
    },
    
    {
      name: this.helper.trans.instant("country"),
      type: "dropDown",
      tooltip: "Country",
      icon: "admin_icons/country.png",
      state: "admin",
      sub: [
        { name: this.helper.trans.instant("country"), state: "country" },
        { name: this.helper.trans.instant("service"), state: "service" },
        { name: this.helper.trans.instant("service_price"), state: "service_price" }
      ]
    },
    {
      name: this.helper.trans.instant("users"),
      type: "link",
      tooltip: "Users",
      icon: "admin_icons/user.png",
      state: "admin/users"
    },
    {
      name: this.helper.trans.instant("providers"),
      type: "link",
      tooltip: "Providers",
      icon: "admin_icons/providers.png",
      state: "admin/providers"
    }
    ,
    {
      name: this.helper.trans.instant("companies"),
      type: "link",
      tooltip: "Companies",
      icon: "admin_icons/company.png",
      state: "admin/companies"
    },
    {
      name: this.helper.trans.instant("ongoing_requests"),
      type: "link",
      tooltip: "Requests",
      icon: "admin_icons/forward_request.png",
      state: "admin/requests"
    },
    {
      name: this.helper.trans.instant("history"),
      type: "link",
      tooltip: "History",
      icon: "admin_icons/history.png",
      state: "admin/history"
    },
    {
      name: this.helper.trans.instant("earning"),
      type: "link",
      tooltip: "Earning",
      icon: "admin_icons/earnings.png",
      state: "admin/earning"
    }
    ,
    {
      name: this.helper.trans.instant("wallet_history"),
      type: "link",
      tooltip: "Wallet History",
      icon: "admin_icons/wallet.png",
      state: "admin/wallet_history"
    },
    {
      name: this.helper.trans.instant("settings"),
      type: "link",
      tooltip: "Settings",
      icon: "admin_icons/settings.png",
      state: "admin/settings"
    },
    {
      name: this.helper.trans.instant("documents"),
      type: "link",
      tooltip: "Documents",
      icon: "admin_icons/documents.png",
      state: "admin/documents"
    },
	{
      name: this.helper.trans.instant("emails"),
      type: "link",
      tooltip: "Emails",
      icon: "admin_icons/emails.png",
      state: "admin/emails"
    },
    {
      name: this.helper.trans.instant("admin_list"),
      type: "link",
      tooltip: "Admin List",
      icon: "admin_icons/admin.png",
      state: "admin/list"
    },
    {
      name: this.helper.trans.instant("notifications"),
      type: "link",
      tooltip: "Notifications",
      icon: "admin_icons/admin.png",
      state: "admin/notifications"
    }

  ];

  separatorMenu: IMenuItem[] = [
    {
      type: "separator",
      name: "Custom components"
    },
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard"
    }
  ];

  plainMenu: IMenuItem[] = [
    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "Dashboard",
      icon: "dashboard",
      state: "dashboard"
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case "separator-menu":
        this.menuItems.next(this.separatorMenu);
        break;
      case "icon-menu":
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }
}
