import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { SigninComponent } from './views/admin/sessions/signin/signin.component';

export const rootRouterConfig: Routes = [
  
  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { 
        path: '', 
        loadChildren: './views/admin/sessions/sessions.module#SessionsModule',
        data: { title: 'Admin'} 
      }
    ]
  },
  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { 
        path: 'admin', 
        loadChildren: './views/admin/admin.module#AdminModule', 
        data: { title: 'Admin', breadcrumb: 'Admin'}
      }
    ]
  },
  {
    path: '**',    
    redirectTo: 'login'
  }
];

